import axios from '../../plugins/axios';
export const notifications_module = {
    state: ()=>({
        pending: false,
        data: {
            current_page: 1,
            data: [],
            last_page: null,
            searchedKeyword:'',
        },
        message: null,
        success: null,
        error: null
    }),
    mutations: {
        fetch_notifications_request(state){

            state.pending = true;

        },
        fetch_notifications_success(state, payload){

            if(payload.loadData == 'new'){
                
                state.data.data = payload.email_notifications.data

            }
            if(payload.loadData == 'more'){

                payload.email_notifications.data.forEach(element => {

                    state.data.data.push(element);

                });
                
            }

            state.data.current_page = payload.email_notifications.current_page;
            state.data.last_page = payload.email_notifications.last_page;
            state.pending = false;
            state.success = true;
            state.error = null;
            state.message = payload.message;

        },
        fetch_notifications_failure(state, payload){

            state.data = payload;
            state.pending = false;
            state.success = false;
            state.error = payload.error;
            state.message = payload.message;

        },
        add_notifications_list(state,payload){

            state.data.data.unshift(payload);

        },
        update_notifications_list(state,payload){

            state.data.data[payload.index] = payload.category;

        },
        update_notifications_status(state,payload){

            state.data.data[payload.index].isActive = payload.updatedCategory.isActive;

        },
        delete_notifications_list(state,payload){

            state.data.data.splice(payload,1);

        },
        notifications_bulk_action(state,payload){

            if(payload.action == 'delete'){
            
                state.data.data = state.data.data.filter((notification)=>{

                    if(!payload.listIds.includes(notification.id)){

                        return notification;

                    }

                });

            }

        },
        update_searched_keyword(state,payload){

            state.data.searchedKeyword = payload.keyword

        },
        remove_searched_keyword(state){
            
            state.data.searchedKeyword = ''

        },
        CLEAR_MODULE(state){

            state.pending = false;
            state.message = null;
            state.success = null;
            state.error = null;

            state.data.current_page = 1;
            state.data.data = [];
            state.data.last_page = null;

        }
    },
    actions:{
        async fetchStoreNotificationsRequest({commit},{size, page, loadData}){

            commit('fetch_notifications_request');
            try{

                let res = null

                if(this.state.notifications_module.data.searchedKeyword.length > 0){

                    res = await axios.get('/email-notification/all',{
                        params:{
                            search: this.state.notifications_module.data.searchedKeyword,
                            offset: size,
                            page: page
                        }
                    })

                }else{

                    res = await axios.get('/email-notification/all',{
                        params:{
                            offset: size,
                            page: page
                        }
                    })

                }
                if(res.data.status_code == '1241'){

                    res.data.loadData = loadData;

                    commit('fetch_notifications_success', res.data);

                }
            }catch(ex){

                commit('fetch_notifications_failure', {error: ex, message: ex});
                
            }

        }

    },
    getters: {
        notifications(state){

            return state.data.data;

        },
        keyword(state){

            return state.data.searchedKeyword;

        },
        isPending(state){

            return state.pending;

        },
        currentPage(state){

            return state.data.current_page;

        },
        lastPage(state){

            return state.data.last_page;

        },
        message(state){

            return state.message;
            
        },
        error:(state)=>state.error
    }
}