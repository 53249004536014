import axios from '../../plugins/axios';
export const gallery_module = {
    state: ()=>({
        pending: false,
        data: {
            current_page: 1,
            data: [],
            last_page: null,
        },
        message: null,
        success: null,
        error: null
    }),
    mutations: {
        fetch_gallery_request(state){

            state.pending = true;

        },
        fetch_gallery_success(state, payload){

            if(payload.loadData == 'new'){

                state.data.data = payload.gallery.data;

            }

            if(payload.loadData == 'more'){

                payload.gallery.data.forEach((galleryItem) => {

                    state.data.data.push(galleryItem);

                });

            }

            state.data.current_page = payload.gallery.current_page;
            state.data.last_page = payload.gallery.last_page;
            state.pending = false;
            state.success = true;
            state.error = null;
            state.message = payload.message;

        },
        fetch_gallery_failure(state, payload){
            
            state.data = payload;
            state.pending = false;
            state.success = false;
            state.error = payload.error;
            state.message = payload.message;

        },

        delete_gallery_item(state, payload){

            let banners = state.data.data.filter(gallery => gallery.id != payload.id);

            state.data.data = banners;

        },
        CLEAR_MODULE(state){

            state.pending = false;
            state.message = null;
            state.success = null;
            state.error = null;

            state.data.current_page = 1;
            state.data.data = [];
            state.data.last_page = null;

        }
    },
    actions:{
        async fetchGalleryRequest({commit},{size, page, loadData}){

            commit('fetch_gallery_request');
            try{
                let res = await axios.get('/gallery/all',{
                    params:{
                        offset: size,
                        page: page
                    }
                });
                if(res.data.status_code == '1290'){

                    res.data.loadData = loadData;

                    commit('fetch_gallery_success', res.data);

                }
            }catch(ex){

                commit('fetch_gallery_failure', {error: ex, message: ex});
                
            }
        }
    },
    getters: {
        gallery(state){

            return state.data.data;

        },
        isPending(state){

            return state.pending;

        },
        currentPage(state){

            return state.data.current_page;

        },
        lastPage(state){

            return state.data.last_page;

        },
        message(state){

            return state.message;
            
        },
        error:(state)=>state.error
    }
}