import axios from '../../plugins/axios';
export const products_module = {
    state: ()=>({
        pending: false,
        data: {
            current_page: 1,
            data: [],
            last_page: null,
            // stats:{},
            brands:[],
            filter:{
                categories:[],
                category_ids: [],
                type:[],
                status:[],
                brands:[],
                brand_ids: [],
                tags:[],
                searchedTerm:'',
            },
            allFilters:[],
            query:'',
        },
        message: null,
        success: null,
        error: null
    }),
    mutations: {
        fetch_products_request(state){

            state.pending = true;

        },
        fetch_products_success(state, payload){

            if(payload.loadData == 'new'){

                state.data.data = payload.products.data.map(product=>{

                    let obj = product;

                    if(obj.seo && obj.seo.keywords){

                        obj.seo.keywords = obj.seo.keywords.map(key=>{

                            return key.keyword

                        });

                    }else{

                        obj.seo ={
                            keywords : []
                        };

                    }

                    obj.tags = obj.tags.map(tag=>{

                        return tag.name

                    });

                    return obj;

                });
    
                payload.products.data.forEach(product => {

                    if(product.brand && !state.data.brands.includes(product.brand)){

                        state.data.brands.push(product.brand);

                    }

                });

            }

            if(payload.loadData == 'more'){
                
                let moreProducts = payload.products.data.map(product=>{

                    let obj = product;

                    if(obj.seo && obj.seo.keywords){

                        obj.seo.keywords = obj.seo.keywords.map(key=>{

                            return key.keyword

                        });

                    }else{

                        obj.seo = {
                            keywords : []
                        };

                    }

                    obj.tags = obj.tags.map(tag=>{

                        return tag.name

                    });

                    return obj;

                });

                moreProducts.forEach(element => {

                    state.data.data.push(element);

                });
                
                payload.products.data.forEach(product => {

                    if(product.brand && !state.data.brands.includes(product.brand)){

                        state.data.brands.push(product.brand);

                    }

                });

            }

            state.data.current_page = payload.products.current_page;
            state.data.last_page = payload.products.last_page;
            // state.data.stats = payload.stats
            state.pending = false;
            state.success = true;
            state.error = null;
            state.message = payload.message;

        },
        fetch_products_failure(state, payload){

            state.data = payload;
            state.pending = false;
            state.success = false;
            state.error = payload.error;
            state.message = payload.message;

        },
        add_products_list(state,payload){

            payload.tags = payload.tags.map(tag=>{

                return tag.name;

            });

            state.data.data.unshift(payload);

        },
        update_products_list(state,payload){

            payload.updatedProduct.tags = payload.updatedProduct.tags.map(tag=>{

                return tag.name

            });
            
            state.data.data[payload.index] = payload.updatedProduct;

        },
        find_and_update_product(state,payload){

            let index = state.data.data.findIndex((dataItem) => dataItem.id == payload.product.id);

            if(index != -1){

                payload.product.tags = payload.product.tags.map(tag=>{

                    return tag.name
    
                });

                state.data.data[index] = payload.product

            }

        },
        update_product_status(state,payload){

            payload.updatedProduct.tags = payload.updatedProduct.tags.map(tag=>{

                return tag.name;

            });

            state.data.data[payload.index].isActive = payload.updatedProduct.isActive;

        },
        delete_products_list(state,payload){

            state.data.data.splice(payload,1);

        },
        products_bulk_action(state,payload){

            if(payload.action == 'isFeatured'){

                state.data.data = state.data.data.filter((product)=>{

                    if(payload.listIds.includes(product.id)){

                        product.isFeatured = payload.selection

                        return product

                    }else{

                        return product
                        
                    }

                });

            }else if(payload.action == 'delete'){

                state.data.data = state.data.data.filter((product)=>{

                    if(!payload.listIds.includes(product.id)){

                        return product
                        
                    }

                });

            }else if(payload.action == 'statusChange'){

                state.data.data = state.data.data.filter((product)=>{

                    if(payload.listIds.includes(product.id)){

                        product.isActive = payload.status

                        return product

                    }else{

                        return product

                    }

                });

            }

        },
        delete_product_tag(state,payload){

            state.data.data[payload.pIndex].tags.splice(state.data.data[payload.pIndex].tags.indexOf(payload.tagName),1)

        },
        update_product_review_status(state,payload){

            state.data.data[payload.product_index].reviews[payload.review_index] = payload.review

        },
        update_product_filters(state,payload){

            state.data.filter.categories = payload.categories
            state.data.filter.category_ids = payload.category_ids
            state.data.filter.type = payload.type
            state.data.filter.status = payload.status
            state.data.filter.brands = payload.brands
            state.data.filter.brand_ids = payload.brand_ids
            state.data.filter.tags = payload.tags
            state.data.filter.searchedTerm = payload.searchedTerm
            state.data.query = ''

            let category_ids = state.data.filter.category_ids
            let brand_ids = state.data.filter.brand_ids
            let tags = state.data.filter.tags

            if(category_ids && category_ids.length > 0){

                for(let i = 0;i<category_ids.length;i++){

                    let arr = ''

                    if(i == 0){

                        arr = "categories["+i+"]"+"="+category_ids[i];

                    }else{

                        arr = "&categories["+i+"]"+"="+category_ids[i];

                    }

                    if(i == 0){

                        state.data.query = '?'+state.data.query + arr

                    }else{

                        state.data.query = state.data.query + arr

                    }

                }

            }

            if(brand_ids.length > 0){

                for(let i = 0;i<brand_ids.length;i++){

                    let arr = ''

                    if(state.data.query.includes("?")){

                        arr = "&brands["+i+"]"+"="+brand_ids[i];

                    }else{

                        arr = "brands["+i+"]"+"="+brand_ids[i];

                    }

                    if(state.data.query.includes("?")){

                        state.data.query = state.data.query + arr

                    }else{

                        state.data.query = '?'+state.data.query + arr

                    }

                }

            }

            if(tags.length > 0){

                for(let i = 0;i<tags.length;i++){

                    let arr = ''

                    if(state.data.query.includes("?")){

                        arr = "&tags["+i+"]"+"="+tags[i]

                    }else{

                        arr = "tags["+i+"]"+"="+tags[i]

                    }

                    if(state.data.query.includes("?")){

                        state.data.query = state.data.query + arr

                    }else{

                        state.data.query = '?'+state.data.query + arr

                    }

                }

            }

            if(state.data.filter.type.length > 0){

                if(state.data.query.includes('?')){

                    state.data.query = state.data.query + "&type=" + state.data.filter.type

                }else{

                    state.data.query = state.data.query + "?type=" + state.data.filter.type

                }

            }

            if(state.data.filter.status.length > 0){

                if(state.data.query.includes('?')){

                    state.data.query = state.data.query + "&status=" + state.data.filter.status

                }else{

                    state.data.query = state.data.query + "?status=" + state.data.filter.status

                }

            }
            
            if(state.data.filter.searchedTerm){

                if(state.data.query.includes('?')){

                    state.data.query = state.data.query + "&search="+ state.data.filter.searchedTerm

                }else{

                    state.data.query = state.data.query + "?search="+ state.data.filter.searchedTerm

                }

            }

        },
        update_product_allFilters(state,payload){

            state.data.allFilters = payload

        },
        remove_product_filters(state){

            state.data.filter.categories = []
            state.data.filter.category_ids = []
            state.data.filter.type = []
            state.data.filter.status = []
            state.data.filter.brands = []
            state.data.filter.brand_ids = []
            state.data.filter.tags = []

            state.data.query = ''
            state.data.allFilters = []
            
        },
        remove_product_search(state){

            state.data.filter.searchedTerm = ''

            state.data.query = ''
            state.data.allFilters = []

        },
        CLEAR_MODULE(state){

            state.pending = false
            state.message = null
            state.success = null
            state.error = null

            state.data.current_page = 1
            state.data.data = []
            state.data.last_page = null
            state.data.stats = {}
            state.data.brands = []
            state.data.filter = {
                categories:[],
                category_ids:[],
                type:[],
                status:[],
                brands:[],
                brand_ids:[],
                tags:[],
                searchedTerm:'',
            }

            state.data.query= ''

        }
    },
    actions:{
        async fetchStoreProductsRequest({commit},{size, page, loadData}){

            commit('fetch_products_request');
            try{
                let res = null;

                if(this.state.products_module.data.query.length > 0){

                    if(this.state.products_module.data.query.indexOf("?") == 0){

                        res = await axios.get(`/getProducts${this.state.products_module.data.query}`,{
                            params:{
                                offset: size,
                                page: page
                            }
                        });

                    }else{

                        res = await axios.get(`/getProducts?${this.state.products_module.data.query}`,{
                            params:{
                                offset: size,
                                page: page
                            }
                        });

                    }

                }else{

                    res = await axios.get(`/getProducts`,{
                        params:{
                            offset: size,
                            page: page
                        }
                    });
                    
                }
                if(res.data.status_code == '1007'){

                    res.data.loadData = loadData

                    commit('fetch_products_success', res.data);

                }
            }catch(ex){

                commit('fetch_products_failure', {error: ex, message: ex});
                
            }
            
        }

    },
    getters: {
        products(state){

            return state.data.data;

        },
        // stats(state){
        //     return state.data.stats
        // },
        brands(state){

            return state.data.brands;

        },
        filters(state){

            return state.data.filter;

        },
        allFilters(state){

            return state.data.allFilters;

        },
        currentPage(state){

            return state.data.current_page;

        },
        lastPage(state){

            return state.data.last_page;

        },
        isPending(state){

            return state.pending;

        },
        message(state){

            return state.message;
            
        },
        error:(state)=>state.error
    }
}