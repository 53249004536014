import axios from '../../plugins/axios';
export const robot = {
    state: ()=>({
        pending: false,
        data: {
            robot: ''
        },
        message: null,
        success: null,
        error: null
    }),
    mutations: {
        fetch_robot_request(state){

            state.pending = true;

        },
        fetch_robot_success(state, payload){

            state.data.robot = payload.robot.value;
            state.pending = false;
            state.success = true;
            state.error = null;
            state.message = payload.message;

        },
        fetch_robot_failure(state, payload){

            state.pending = false;
            state.success = false;
            state.error = payload.error;
            state.message = payload.message;


        },
        update_robot_txt(state, payload){

            state.data.robot = payload;

        },
        CLEAR_MODULE(state){

            state.pending = false;
            state.message = null;
            state.success = null;
            state.error = null;

            state.data.robot = '';

        }
    },
    actions:{
        async fetchRobotRequest({commit}){
            commit('fetch_robot_request');
            try{
                let res = await axios.get('robots.txt/all');
                if(res.data.status_code == '1034'){

                    commit('fetch_robot_success', res.data);

                }
            }catch(ex){

                commit('fetch_robot_failure', {error: ex, message: ex});
                
            }
        }
    },
    getters: {
        robots(state){

            return state.data.robot;

        },
        isPending(state){

            return state.pending;

        },
        message(state){

            return state.message;
            
        },
        error:(state)=>state.error
    }
}