import axios from '../../plugins/axios';
export const blogCategories_module = {
    state: ()=>({
        pending: false,
        data: {
            current_page: 1,
            data: [],
            last_page: null,
            searchedKeyword:'',
        },
        message: null,
        success: null,
        error: null
    }),
    mutations: {
        fetch_blogCategories_request(state){
            
            state.pending = true;

        },
        fetch_blogCategories_success(state, payload){

            if(payload.loadData == 'new'){

                state.data.data = payload.categories.data

            }

            if(payload.loadData == 'more'){

                let moreCategories = payload.categories.data

                moreCategories.forEach(element => {

                    state.data.data.push(element);

                });

            }

            state.data.current_page = payload.categories.current_page
            state.data.last_page = payload.categories.last_page
            state.pending = false;
            state.success = true;
            state.error = null;
            state.message = payload.message;

        },
        fetch_blogCategories_failure(state, payload){

            state.data = payload;
            state.pending = false;
            state.success = false;
            state.error = payload.error;
            state.message = payload.message

        },
        add_blogCategories_list(state,payload){

            state.data.data.unshift(payload);

        },
        update_blogCategories_list(state,payload){

            const index = state.data.data.findIndex(x=>x.id === payload.id)
  
            if(index !== -1){

                state.data.data[index] = payload;

            }

        },
        update_blogCategories_status(state,payload){

            state.data.data[payload.index].isActive = payload.updatedBlogCategory.isActive

        },
        delete_blogCategories_list(state,payload){

            let tempCategory = state.data.data[payload]

            state.data.data.forEach((category)=>{ 

                if(category.parent_id && (category.parent_id == tempCategory.id)){

                    category.parent_id = null

                    category.parents = []

                }

            });

            state.data.data.splice(payload,1);
    
        },
        categories_bulk_action(state,payload){
            
            if(payload.action == 'delete'){

                state.data.data = state.data.data.filter((category)=>{

                    if(!payload.listIds.includes(category.id)){

                        return category;

                    }else{

                        if(category.created_by_user == null){

                            return category;

                        }

                    }

                });

            }else if(payload.action == 'statusChange'){

                state.data.data.forEach((category) =>{

                    if(payload.listIds.includes(category.id)){

                        category.isActive = payload.status;

                    }
                    if(payload.listIds.includes(category.parent_id) && payload.status == 0){

                        category.parent_id = null;

                        category.parents = [];
                        
                    }

                });

            }
            
        },
        update_searched_keyword(state,payload){ 

            state.data.searchedKeyword = payload.keyword

        },
        remove_searched_keyword(state){
            
            state.data.searchedKeyword = ''

        },
        CLEAR_MODULE(state){

            state.pending = false
            state.message = null
            state.success = null
            state.error = null

            state.data.current_page = 1
            state.data.data = []
            state.data.last_page = null,
            state.data.searchedKeyword= ''
            
        }
    },
    actions:{
        async fetchStoreBlogCategoriesRequest({commit},{size, page, loadData}){

            commit('fetch_blogCategories_request');
            try{

                let res = null;

                if(this.state.blogCategories_module.data.searchedKeyword.length > 0){
                    
                    res = await axios.get('/blog-category/all',{
                        params:{
                            search: this.state.blogCategories_module.data.searchedKeyword,
                            offset: size,
                            page: page
                        }
                    })

                }else{

                    res = await axios.get('/blog-category/all',{
                        params:{
                            offset: size,
                            page: page
                        }
                    })

                }
                if(res.data.status_code == '1042'){
                    res.data.loadData = loadData

                    commit('fetch_blogCategories_success', res.data)
                }
            }catch(ex){
                commit('fetch_blogCategories_failure', {error: ex, message: ex})
            }
        }
    },
    getters: {
        blogCategories(state){

            return state.data.data;

        },
        isPending(state){

            return state.pending;

        },
        currentPage(state){

            return state.data.current_page;

        },
        lastPage(state){

            return state.data.last_page;

        },
        keyword(state){

            return state.data.searchedKeyword;

        },
        message(state){

            return state.message;
            
        },
        error:(state)=>state.error
    }
}